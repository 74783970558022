<template>
<div v-if="field.ds.valueType == 'RANGE' && field.range == 'minmax'" class="row">
  <div class="col-12">
    <inquiry-field-input :label="label + ' ' + $t('fieldMin')" @update="updateMin"
      :outlined="outlined"
      :type="field.type" :value="valueMin" :unit="field.unit" :filled="filled" :rounded="rounded" ></inquiry-field-input>
  </div>

  <div class="col-12">
    <inquiry-field-input :label="label + ' ' + $t('fieldMax')" @update="updateMax"
    :type="field.type" :value="valueMax" :unit="field.unit" :filled="filled" :rounded="rounded"
   :outlined="outlined"></inquiry-field-input>
  </div>
</div>
<div v-else>
  <inquiry-field-input
    :label="label"
    :type="field.type"
    @update="update"
    :unit="field.unit"
    :value="value"
    :filled="filled"
    :rounded="rounded"
    :outlined="outlined"
    >
  </inquiry-field-input>

  <v-select v-if="field.type == 'select'"
    :label="label"
    :value="field.currentValue"
    @change="updateData"
    :items="field.values"
    return-object
    item-text="title"
    item-value="value"
    clearable
    :filled="filled"
    :rounded="rounded"
    :outlined="outlined"
    >
  </v-select>
</div>
</template>

<script>
import InquiryFieldInput from "./InquiryFieldInput";

export default {
  name: "InquiryField",

  components: {
    InquiryFieldInput
  },

  props: {
    marketingType: String,
    field: Object,
    filled: {
      type: Boolean,
      default: true
    },
    rounded: {
      type: Boolean,
      default: false
    },
    outlined: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    label() {
      let label = this.field.label;

      if (this.marketingType && this.field.marketingTypeLabel != null) {
        label = this.field.marketingTypeLabel[this.marketingType.toUpperCase()];
      }

      if (this.field.range == "min") {
        return label + " " + this.$t("fieldMin");
      }

      if (this.field.range == "max") {
        return label + " " + this.$t("fieldMax");
      }

      return label;
    },

    value() {
      const ds = this.field.ds;

      if (this.field.range == "min") {
        return ds.minValue;
      }

      if (this.field.range == "max") {
        return ds.maxValue;
      }

      return ds.value;
    },

    valueMin() {
      return this.field.ds.minValue;
    },

    valueMax() {
      return this.field.ds.maxValue;
    }
  },

  methods: {
    update(value) {
      const ds = this.field.ds;

      if (this.field.range == "min") {
        ds.minValue = value;
      } else if (this.field.range == "max") {
        ds.maxValue = value;
      } else {
        ds.value = value;
      }

      this.$emit("update", { id: this.field.id, ds });
    },

    updateMin(value) {
      const ds = this.field.ds;
      ds.minValue = value;
      this.$emit("update", { id: this.field.id, ds });
    },

    updateMax(value) {
      const ds = this.field.ds;
      ds.maxValue = value;
      this.$emit("update", { id: this.field.id, ds });
    },

    updateData(e) {
      const ds = this.field.ds;
      ds.data = e.value;
      this.$emit("update", { id: this.field.id, ds });
    }
  }
};
</script>
