export default () => {
  const elmButton = document.querySelectorAll("a[href $= '#bewertung']");
  if (elmButton) {
    const target = document.querySelector("#bewertung-modal");

    for (const elem of elmButton) {
      elem.addEventListener("click", () => {
        target.querySelector("button").click();
      });
    }
  }
}
