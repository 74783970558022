<template>
<v-app>
<v-dialog
    v-model="dialog"
    width="750"
  >
    <v-card>
      <v-card-title
        class="headline bg-primary w-100"
        primary-title
      >
          Wertermittlung
          <v-spacer></v-spacer>
          <div
            class="float-right"><v-btn
            icon
            dark
            @click="dialog = false">
            <span class="fal fa-2x fa-times"></span>
          </v-btn>
          </div>
      </v-card-title>

      <v-card-text>
        <app-wrapper :standalone="true">
          <lead-wizard></lead-wizard>
        </app-wrapper>
      </v-card-text>
    </v-card>
</v-dialog>
</v-app>
</template>

<script>
import AppWrapper from "../AppWrapper";
import LeadWizard from "./LeadWizard";

export default {
  name: "LeadWizardStandaloneApp",

  components: {
    AppWrapper,
    LeadWizard
  },

  data() {
    return {
      dialog: false
    }
  },

  mounted() {
    const elms = document.querySelectorAll("a[href $= '#bewertung']");
    if (elms) {
      for (const elm of elms) {
        elm.addEventListener("click", (e) => {
          e.preventDefault();
          this.dialog = true;
        });
      }
    }

    const hash = window.location.hash;
    if (hash && hash.substr(1) == "bewertung") {
      this.dialog = true;
    }
  }
};
</script>
