import ImmoappLoader from "../misc/ImmoappLoader";
import SearchAppHeader from "../components/immoapp/SearchAppHeader";

export default function() {
  const element = document.querySelector("#vue-immoapp-search-header");
  if (!element) {
    return;
  }

  ImmoappLoader(element, SearchAppHeader, {
    props: {
      categories: JSON.parse(element.dataset.categories),
      params: JSON.parse(element.dataset.params),
      action: element.dataset.action
    }
  });
}
